import useSWR from 'swr';
import { getPersonalElements } from '@api/interfaces/personalElementsApi';
import useCustomer from '@hooks/useCustomer';

const usePersonalElements = () => {
  const { customer } = useCustomer();
  const shouldFetch = Boolean(customer) && !customer?.isAnonymous && !customer?.isB2BCustomer;
  const key = {
    endpoint: 'axfood/rest/personalElement',
    customerId: customer?.uid,
  };
  const { data } = useSWR(shouldFetch ? key : null, () => getPersonalElements());
  return {
    personalElementsData: data,
  };
};

export default usePersonalElements;
