import useCustomer from '@hooks/useCustomer';
import Heading from '@atoms/Heading/Heading';
import useTranslation from 'next-translate/useTranslation';
import PersonalElementsCard from '@molecules/PersonalElementsCard/PersonalElementsCard';
import { useAppSelector } from '@hooks/useAppDispatch';
import { StyledPersonalElements, StyledPersonalElementsWrapper } from './PersonalElements.styles';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import useVouchers from '@hooks/useVouchers';
import useUserAgent from '@hooks/useUserAgent';
import usePersonalElements from './usePersonalElements';

interface Props {
  cmsData: any;
}

const PersonalElements = ({ cmsData }: Props) => {
  const { t } = useTranslation('personalElements');
  const { customer } = useCustomer();
  const sideMenuIsOpen = useAppSelector(selectSideNavMenuIsOpen);
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const { onlineVouchers, offlineVouchers } = useVouchers();
  const { personalElementsData } = usePersonalElements();
  const onlineAvailable = (onlineVouchers?.available?.length ?? 0) > 0;
  const offlineAvailable = (offlineVouchers?.available?.length ?? 0) > 0;
  const haveVouchers = onlineAvailable || offlineAvailable;
  const hasAllItems = !!personalElementsData?.order && haveVouchers && !!personalElementsData.digitalReceipt;
  const hasOneOrMoreItems =
    Boolean(personalElementsData?.order) ||
    Boolean(personalElementsData?.digitalReceipt) ||
    onlineAvailable ||
    offlineAvailable;
  const { isNativeApp } = useUserAgent();

  return (!isNativeApp && cmsData?.hideInWeb !== 'true') || (isNativeApp && cmsData?.hideInApp !== 'true') ? (
    <>
      {!customer?.isAnonymous && !customer?.isB2BCustomer && hasOneOrMoreItems && (
        <StyledPersonalElements>
          <Heading variant="h2" size="small">
            {t('title')} <span data-hj-suppress="true">{customer?.firstName}</span>
          </Heading>
          <StyledPersonalElementsWrapper
            hasAllItems={hasAllItems}
            isMenuAndCartOpen={sideMenuIsOpen && isCartPreviewOpen}
          >
            {personalElementsData?.order && (
              <PersonalElementsCard personalElement={personalElementsData} variant="order" />
            )}
            {personalElementsData?.digitalReceipt && (
              <PersonalElementsCard personalElement={personalElementsData} variant="receipt" />
            )}
            {(onlineAvailable || offlineAvailable) && personalElementsData && (
              <PersonalElementsCard personalElement={personalElementsData} variant="voucher" />
            )}
          </StyledPersonalElementsWrapper>
        </StyledPersonalElements>
      )}
    </>
  ) : null;
};

export default PersonalElements;
