import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Config from '@config';

export const StyledPersonalElements = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
`;

export const StyledPersonalElementsWrapper = styled.div<{ hasAllItems: boolean; isMenuAndCartOpen: boolean }>`
  display: grid;
  grid-gap: ${rem(10)};
  grid-template-columns: 1fr;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    grid-gap: ${rem(16)};
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    grid-template-columns: ${(props) => (props.hasAllItems ? '1fr 1fr 1fr' : '1fr 1fr')};
  }

  ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${(props) =>
    props.isMenuAndCartOpen &&
    css`
      ${Config.MEDIA.IS_DESKTOP_MEDIUM} {
        grid-template-columns: ${props.hasAllItems ? '1fr' : '1fr 1fr'};
      }
    `}
`;
